import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import Styles from "./Admin.module.css";
import axios from "axios";
import Select from "react-select";
import Plus from "../../assets/imgpsh_fullsize_anim.png";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { FormattedMessage } from "react-intl";
import SelectLanguage from "../SelectLanguage";

const addSelectcolorStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      "&:hover": { borderColor: "#1B535C", boxShadow: "0 0 0 1px #1B535C" },
      backgroundColor: "#F3F2F2",
      height: "64px",
      width: "100%",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0 0 0 1px #1B535C" : "none",
      borderColor: state.isFocused ? "#1B535C" : "transparent",
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#F3F2F2",
    marginTop: "12px",
    borderRadius: 10,
    border: "1px solid #1B535C",
    color: "#373B3B",
    padding: 0,
    overflow: "hidden",
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      color: "#373B3B",
      "&:active": { backgroundColor: "#adadad" },
      backgroundColor:
        state.isFocussed || state.isSelected ? "#adadad" : "#F3F2F2",
      margin: 0,
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, color: "#818383", fontSize: "16px" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
const deleteSelectcolorStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      "&:hover": { borderColor: "#1B535C", boxShadow: "0 0 0 1px #1B535C" },
      backgroundColor: "#F3F2F2",
      height: "64px",
      width: "280px",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0 0 0 1px #1B535C" : "none",
      borderColor: state.isFocused ? "#1B535C" : "transparent",
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#F3F2F2",
    marginTop: "12px",
    borderRadius: 10,
    border: "1px solid #1B535C",
    color: "#373B3B",
    padding: 0,
    width: "280px",
    overflow: "hidden",
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      color: "#373B3B",
      "&:active": { backgroundColor: "#adadad" },
      backgroundColor:
        state.isFocussed || state.isSelected ? "#adadad" : "#F3F2F2",
      margin: 0,
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, color: "#818383", fontSize: "16px" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
export default (props) => {
  useEffect(() => {
    getData();
    getClasses();
    getLanguages();
  }, []);
  const history = useHistory();
  const [students, setStudents] = useState();
  const [wordList, setWordList] = useState();
  const [classesOptions, setClassesOptions] = useState([]);
  const [lessonsOptions, setLessonsOptions] = useState([]);
  const [classId, setClassId] = useState();
  const [lessonId, setLessonId] = useState();
  const [word, setWord] = useState();
  const [name, setName] = useState();
  const [studentID, setStudentID] = useState();
  const [deleteClassId, setDeleteClassId] = useState();
  const [deleteLessonId, setDeleteLessonId] = useState();
  const [deleteWordList, setDeleteWordList] = useState();
  const [show, setShow] = useState(false);
  const [className, setClassName] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [languageKey, setLanguageKey] = useState(null);
  const [lesson, setLesson] = useState(null);
  const [classes, setClasses] = useState(null);
  const handleClose = () => setShow(false);

  const addClass = async () => {
    let res = await axios.post(
      "/api/v1/class",
      { name: className, language_id: languageKey },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    const response = res;
    if (response.data.classes) {
      setClassesOptions([
        ...classesOptions,
        {
          value: response.data.classes.id,
          label: response.data.classes.name,
        },
      ]);
      setClassId(response.data.classes.id);
      let res = await axios.get(
        `/api/v1/lessons?class_id=${response.data.classes.id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.data.lessons) {
        setLessonsOptions(
          res.data.data.lessons.map((obj) => {
            return {
              value: obj.id,
              label: obj.name,
            };
          })
        );
      }
    }
    setShow(false);
  };
  const getClasses = async () => {
    let res = await axios.get("/api/v1/classes", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.data.data.classes) {
      setClassesOptions(
        res.data.data.classes.map((obj) => {
          return {
            value: obj.id,
            label: obj.name,
          };
        })
      );
    }
  };

  const getLanguages = async () => {
    let res = await axios.get("/api/v1/languages", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.data.data.languages) {
      setLanguages(
        res.data.data.languages.map((obj) => {
          return {
            value: obj.id,
            label: obj.label,
          };
        })
      );
    }
  };
  const getData = async () => {
    let res = await axios.get("/api/v1/students", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.data.data.school) {
      setName(res.data.data.school.name);
    }
    if (res.data.data) {
      setStudents(
        res.data.data.students.map((obj) => {
          return {
            student_number: obj.student_number,
            school_id: obj.school_id,
            student_id: obj.id,
          };
        })
      );
    }
  };
  const handleUpload = async (e) => {
    let formdata = new FormData();
    formdata.append("students_info", e.target.files[0], e.target.files[0].name);
    if (formdata) {
      let res = await axios.post("/api/v1/students-bulk-upload", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (res.data.status) {
        getData();
      }
    }
  };

  const handleChangeClasses = async (newValue, actionMeta) => {
    if (newValue.__isNew__) {
      setClassName(newValue.label);
      setShow(true);
    } else {
      setClassId(newValue.value);
      let res = await axios.get(`/api/v1/lessons?class_id=${newValue.value}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (res.data.data.lessons) {
        setLessonsOptions(
          res.data.data.lessons.map((obj) => {
            return {
              value: obj.id,
              label: obj.name,
            };
          })
        );
      }
    }

    console.groupEnd();
  };

  const handleChangeLessons = async (newValue, actionMeta) => {
    if (newValue.__isNew__) {
      let res = await axios.post(
        "/api/v1/lesson",
        { name: newValue.label, class_id: classId },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      const response = res;
      if (response.data.lessons) {
        setLessonsOptions([
          ...lessonsOptions,
          {
            value: response.data && response.data.lessons.id,
            label: response.data && response.data.lessons.name,
          },
        ]);
        setLessonId(response.data.lessons.id);
        let res = await axios.get(
          `/api/v1/wordlist?lesson_id=${response.data.lessons.id}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.data.words) {
          setWordList(
            res.data.data.words.map((obj) => {
              return {
                name: obj.text,
                id: obj.id,
              };
            })
          );
        }
      }
    } else {
      setLessonId(newValue.value);
      let res = await axios.get(
        `/api/v1/wordlist?lesson_id=${newValue.value}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.data.words) {
        setWordList(
          res.data.data.words.map((obj) => {
            return {
              name: obj.text,
              id: obj.id,
            };
          })
        );
      }
    }
    console.groupEnd();
  };

  const handleDeleteFetchLessons = async (value) => {
    setClasses(value);
    setDeleteClassId(value.value);
    let res = await axios.get(`/api/v1/lessons?class_id=${value.value}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (res.data.data.lessons) {
      setLessonsOptions(
        res.data.data.lessons.map((obj) => {
          return {
            value: obj.id,
            label: obj.name,
          };
        })
      );
    }
  };

  const handleDeleteFetchWords = async (value) => {
    setLesson(value);
    setDeleteLessonId(value.value);
    let res = await axios.get(`/api/v1/wordlist?lesson_id=${value.value}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (res.data.data.words) {
      setDeleteWordList(
        res.data.data.words.map((obj) => {
          return {
            name: obj.text,
            id: obj.id,
          };
        })
      );
    }
  };
  const addWord = async () => {
    if (lessonId) {
      let res = await axios.post(
        "/api/v1/word",
        { text: word, lesson_id: lessonId },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (res.data.success) {
        setWord("");
      }
      getWords();
    }
  };

  const addStudent = async () => {
    let res = await axios.post(
      "/api/v1/student",
      { studentNumber: studentID },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    if (res.data.status) {
      setStudentID("");
      getData();
    }
  };

  const deleteStudents = async (value) => {
    await axios.delete(`/api/v1/student/${value}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    getData();
  };

  const handleDeleteClass = async () => {
    let res = await axios.delete(`/api/v1/class/${deleteClassId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.data.success) {
      setLesson(null);
      setDeleteWordList([]);
      setClasses(null);
      getClasses();
    }
  };

  const handleDeleteLesson = async () => {
    let res = await axios.delete(`/api/v1/lesson/${deleteLessonId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.data.success) {
      setLesson(null);
      setDeleteWordList([]);
      handleDeleteFetchLessons(classes);
    }
  };

  const handleDeleteWord = async (value) => {
    await axios.delete(`/api/v1/word/${value}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    getWords();
  };

  const handleDeleteWordDeleteLesson = async (value) => {
    await axios.delete(`/api/v1/word/${value}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    handleDeleteFetchWords(lesson);
  };

  const getWords = async () => {
    let res = await axios.get(`/api/v1/wordlist?lesson_id=${lessonId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (res.data.data.words) {
      setWordList(
        res.data.data.words.map((obj) => {
          return {
            name: obj.text,
            id: obj.id,
          };
        })
      );
    }
  };

  const handleWordsUpload = async (e) => {
    let formdata = new FormData();
    formdata.append("lesson_id", lessonId);
    formdata.append(
      "wordLists_info",
      e.target.files[0],
      e.target.files[0].name
    );
    if (formdata) {
      let res = await axios.post("/api/v1/word-list", formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      if (res.status) getWords();
    }
  };

  const handleLogout = async () => {
    let res = await axios.post(`/api/v1/logout`, null, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.data.success) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userID");
      history.push("/");
    }
  };

  return (
    <Container>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="createClassLanguageMessage"
              defaultMessage={"Create Class"}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage
            id="selectLanguageMessage"
            defaultMessage={"Please select the language for"}
          />{" "}
          {className}{" "}
          <FormattedMessage id="classMessage" defaultMessage={"Class"} />
          <br />
          <div style={{ marginTop: "3%" }}>
            <Select
              options={languages}
              onChange={(value) => setLanguageKey(value.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: "#1e525c" }} onClick={addClass}>
            <FormattedMessage
              id="saveChangesMessage"
              defaultMessage={"Save Changes"}
            />
          </Button>
        </Modal.Footer>
      </Modal>
      <Row>
        <SelectLanguage setTextLanguage={props.setTextLanguage} />
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <h1 className={Styles.logoName} onClick={() => handleLogout()}>
            Voiceme
          </h1>
        </Col>
        <Col md={6} sm={12} style={{ paddingLeft: "100px" }}>
          <p className={Styles.schoolOptions}>{name} </p>
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12} className={Styles.adminDiv}>
          <h2 className={Styles.heading}>
            {" "}
            <FormattedMessage
              id="classManagementMessage"
              defaultMessage={"Class Management"}
            />
          </h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12} style={{ display: "flex" }}>
          <h1 className={Styles.name}>
            <FormattedMessage
              id="helloTeacherMessage"
              defaultMessage={"Hello Teacher!"}
            />
          </h1>
          <span className={Styles.logout} onClick={() => handleLogout()}>
            <FormattedMessage id="logOutMessage" defaultMessage={`Log out`} />
          </span>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "5%",
        }}
      >
        <Col md={6} sm={12}>
          <h2 className={Styles.label}>
            <FormattedMessage
              id="createClassMessage"
              defaultMessage={`Create your class`}
            />
          </h2>
          <Row>
            <CreatableSelect
              styles={addSelectcolorStyles}
              options={classesOptions}
              onChange={handleChangeClasses}
              className={`${Styles.input} ${Styles.adminInput}`}
              placeholder={
                <FormattedMessage
                  id="changeClassMessage"
                  defaultMessage={`Write your class name here e.g. beginner, business english, etc`}
                />
              }
            />
          </Row>
        </Col>
        <Col md={6} sm={12} style={{ paddingLeft: "160px" }}>
          <h2 className={Styles.label}>
            <FormattedMessage
              id="uploadStudentMessage"
              defaultMessage={`Upload Student Information`}
            />
          </h2>
          <Row>
            <label htmlFor="input-file" className={Styles.labelButton}>
              <FormattedMessage
                id="uploadStudentFileMessage"
                defaultMessage={`Upload student list from file...`}
              />
            </label>
            <input
              className={`${Styles.input} ${Styles.adminInput}`}
              type="file"
              accept=".csv"
              id="input-file"
              onChange={(e) => handleUpload(e)}
              style={{ display: "none" }}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <Row>
            <CreatableSelect
              styles={addSelectcolorStyles}
              isDisabled={classId ? false : true}
              options={lessonsOptions}
              onChange={handleChangeLessons}
              className={`${Styles.input} ${Styles.adminInput}`}
              placeholder={
                <FormattedMessage
                  id="changeLessonsMessage"
                  defaultMessage={`Write your lesson name here e.g. chapter 1, conversation, restaurant, etc`}
                />
              }
            />
          </Row>
          <Row style={{ display: "flex", marginTop: "2%" }}>
            <div style={{ display: "flex" }}>
              <FormattedMessage
                id="singleWordMessage"
                defaultMessage="Add a single word"
              >
                {(placeholder) => (
                  <input
                    disabled={lessonId ? false : true}
                    className={`${Styles.inputSmall} ${Styles.adminInput}`}
                    type="text"
                    value={word}
                    onChange={(e) => setWord(e.target.value)}
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>

              <img
                alt="plus"
                src={Plus}
                className={`${Styles.addButton} `}
                onClick={() => addWord()}
              />
            </div>
            <label
              disabled={lessonId ? false : true}
              htmlFor="input-word"
              className={Styles.labelButtonSmall}
            >
              <FormattedMessage
                id="uploadwordFileMessage"
                defaultMessage={`Upload word list from file...`}
              />
            </label>
            <input
              disabled={lessonId ? false : true}
              className={`${Styles.input} ${Styles.adminInput}`}
              onChange={(e) => handleWordsUpload(e)}
              type="file"
              id="input-word"
              style={{ display: "none" }}
            />
            <div className={Styles.wordsRemoveList}>
              <Row>
                {wordList &&
                  wordList.map((word) => {
                    return (
                      <>
                        <Col md={6} sm={12}>
                          <h2 className={Styles.studentName}>{word.name}</h2>
                        </Col>
                        <Col
                          md={6}
                          sm={12}
                          style={{ textAlign: "right", paddingRight: "10%" }}
                        >
                          <label
                            className={Styles.remove}
                            onClick={() => handleDeleteWord(word.id)}
                          >
                            <FormattedMessage
                              id="removeMessage"
                              defaultMessage={`Remove`}
                            />
                          </label>
                        </Col>
                        <br />
                      </>
                    );
                  })}
              </Row>
            </div>
          </Row>
        </Col>
        <Col md={6} sm={12} style={{ paddingLeft: "150px" }}>
          <Row>
            <div style={{ display: "flex" }}>
              <FormattedMessage
                id="singleStudentMessage"
                defaultMessage="Add a new student"
              >
                {(placeholder) => (
                  <input
                    className={`${Styles.inputwithadd} ${Styles.adminInput}`}
                    type="text"
                    value={studentID}
                    onChange={(e) => setStudentID(e.target.value)}
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>

              <img
                alt="plus"
                src={Plus}
                className={`${Styles.addbutton} `}
                onClick={() => addStudent()}
              />
            </div>
            <div className={Styles.studentsList}>
              <div>
                {students &&
                  students.map((student) => {
                    return (
                      <Row>
                        <Col md={6} sm={12} style={{ textAlign: "left" }}>
                          <h2 className={Styles.studentName}>
                            {student.student_number}
                          </h2>
                        </Col>
                        <Col md={6} sm={12} style={{ textAlign: "right" }}>
                          <label
                            className={Styles.remove}
                            onClick={() => deleteStudents(student.student_id)}
                          >
                            <FormattedMessage
                              id="removeMessage"
                              defaultMessage={`Remove`}
                            />
                          </label>
                        </Col>
                        <br />
                      </Row>
                    );
                  })}
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6} sm={12} style={{ marginTop: "3%", display: "flex" }}>
          <Select
            options={classesOptions}
            placeholder={
              <FormattedMessage
                id="classesMessage"
                defaultMessage={`Classes`}
              />
            }
            value={classes}
            onChange={(value) => handleDeleteFetchLessons(value)}
            className={Styles.selectedOptions}
            styles={deleteSelectcolorStyles}
          />
          <button
            onClick={() => handleDeleteClass()}
            style={{ marginTop: "6%", marginLeft: "5%" }}
            className={Styles.labelButtonSmallDelete}
          >
            <FormattedMessage id="deleteMessage" defaultMessage={`DELETE`} />
          </button>
        </Col>
        <Col
          md={6}
          sm={12}
          style={{
            width: "100%",
            marginTop: "3%",
            display: "flex",
          }}
        >
          <Select
            options={lessonsOptions}
            onChange={(value) => handleDeleteFetchWords(value)}
            placeholder={
              <FormattedMessage
                id="lessonsMessage"
                defaultMessage={`Lessons`}
              />
            }
            value={lesson}
            isDisabled={deleteClassId ? false : true}
            className={Styles.selectedOptions}
            styles={deleteSelectcolorStyles}
          />
          <button
            style={{ marginTop: "6%", marginLeft: "5%" }}
            onClick={() => handleDeleteLesson()}
            className={Styles.labelButtonSmallDelete}
          >
            <FormattedMessage id="deleteMessage" defaultMessage={`DELETE`} />
          </button>
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12}>
          <Row>
            <div className={Styles.wordsList}>
              <Row>
                {deleteWordList &&
                  deleteWordList.map((word) => {
                    return (
                      <>
                        <Col md={6} sm={12} style={{ textAlign: "left" }}>
                          <h2 className={Styles.studentName}>{word.name}</h2>
                        </Col>
                        <Col md={6} sm={12} style={{ textAlign: "right" }}>
                          <label
                            className={Styles.remove}
                            onClick={() =>
                              handleDeleteWordDeleteLesson(word.id)
                            }
                          >
                            <FormattedMessage
                              id="removeMessage"
                              defaultMessage={`Remove`}
                            />
                          </label>
                        </Col>
                        <br />
                      </>
                    );
                  })}
              </Row>
            </div>
            <div className={Styles.scoresList} />
          </Row>
        </Col>
      </Row>
      <p className={Styles.footer}>
        All copyrights reserved. Voiceme powered by{" "}
        <a
          href="https://www.aispeak.ai"
          target="_blank"
          rel="noopener noreferrer"
          className={Styles.footerSpan}
        >
          aispeak
        </a>{" "}
        technology and{" "}
        <a
          href="https://languagetool.org/"
          target="_blank"
          rel="noopener noreferrer"
          className={Styles.footerSpan}
        >
          languagetool
        </a>{" "}
      </p>
    </Container>
  );
};
