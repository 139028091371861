import React, { useState, useEffect } from "react";
import Styles from "./Home.module.css";
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import Landing from "./../../assets/landing.png";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SelectLanguage from "../SelectLanguage";

const colorStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      "&:hover": { borderColor: "#1B535C", boxShadow: "0 0 0 1px #1B535C" },
      backgroundColor: "#F3F2F2",
      height: "64px",
      width: "410px",
      maxWidth: "100%",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0 0 0 1px #1B535C" : "none",
      borderColor: state.isFocused ? "#1B535C" : "transparent",
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#F3F2F2",
    marginTop: "12px",
    borderRadius: 10,
    border: "1px solid #1B535C",
    color: "#373B3B",
    padding: 0,
    overflow: "hidden",
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      color: "#373B3B",
      "&:active": { backgroundColor: "#adadad" },
      backgroundColor:
        state.isFocussed || state.isSelected ? "#adadad" : "#F3F2F2",
      margin: 0,
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, color: "#818383", fontSize: "16px" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};

export default (props) => {
  const history = useHistory();

  const [schoolOptions, setSchoolOptions] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let res = await axios.get("/api/v1/schools", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    setSchoolOptions(
      res.data.data.schools.map((obj) => {
        return {
          value: obj.slug,
          label: obj.name,
        };
      })
    );
  };

  return (
    <Container className={Styles.container}>
      <Row>
        <SelectLanguage setTextLanguage={props.setTextLanguage} />
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <h1 className={Styles.logoName}>Voiceme</h1>
          <img className={Styles.image} src={Landing} alt="Landing" />
        </Col>
        <Col md={6} sm={12} className={Styles.columnLeft}>
          <h2 className={`${Styles.heading1}`}>
            <FormattedMessage id="helloMessage" defaultMessage="Hello" />
          </h2>
          <h2 className={Styles.heading}>
            <FormattedMessage
              id="helloContentMessage"
              defaultMessage="Let's improve our language speaking skills!"
            />
          </h2>
          <Select
            options={schoolOptions}
            className={Styles.selectedOptions}
            placeholder={
              <FormattedMessage
                id="selectSchoolMessage"
                defaultMessage="Select your school"
              />
            }
            styles={colorStyles}
            onChange={(selected) => {
              history.push(`/${selected.value}`);
            }}
          />
        </Col>
      </Row>
      <p className={Styles.footer}>
        All copyrights reserved. Voiceme powered by{" "}
        <a
          href="https://www.aispeak.ai"
          target="_blank"
          rel="noopener noreferrer"
          className={Styles.footerSpan}
        >
          aispeak
        </a>{" "}
        technology and{" "}
        <a
          href="https://languagetool.org/"
          target="_blank"
          rel="noopener noreferrer"
          className={Styles.footerSpan}
        >
          languagetool
        </a>{" "}
      </p>
    </Container>
  );
};
