import React from "react";
import Styles from "./SelectLanguage.module.css";
import CreatableSelect from "react-select/creatable";

const addSelectcolorStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      "&:hover": { borderColor: "#1B535C", boxShadow: "0 0 0 1px #1B535C" },
      backgroundColor: "#F3F2F2",
      height: "34px",
      width: "100%",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0 0 0 1px #1B535C" : "none",
      borderColor: state.isFocused ? "#1B535C" : "transparent",
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#F3F2F2",
    marginTop: "12px",
    borderRadius: 10,
    border: "1px solid #1B535C",
    color: "#373B3B",
    padding: 0,
    overflow: "hidden",
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      color: "#373B3B",
      "&:active": { backgroundColor: "#adadad" },
      backgroundColor:
        state.isFocussed || state.isSelected ? "#adadad" : "#F3F2F2",
      margin: 0,
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, color: "#818383", fontSize: "16px" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
export default (props) => {
  const classesOptions = [
    {
      value: "en",
      label: "English",
    },
    {
      value: "cn",
      label: "Chinese",
    },
    {
      value: "kr",
      label: "Korean",
    },
  ];

  const handleChange = (value) => {
    console.log(value);
    props.setTextLanguage(value.value);
  };

  return (
    <CreatableSelect
      styles={addSelectcolorStyles}
      options={classesOptions}
      onChange={handleChange}
      className={`${Styles.input}`}
      placeholder={"Select your language"}
    />
  );
};
