import Home from "./Home";
import SchoolHome from "./SchoolHome";
import LessonPage from "./LessonPage";
import Result from "./Result";
import AdminDashboard from "./AdminDashboard";

export default {
  Home,
  SchoolHome,
  LessonPage,
  Result,
  AdminDashboard,
};
