import React, { useState, useEffect } from "react";
import axios from "axios";
import Styles from "./lessonPage.module.css";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import Select from "react-select";
import LessonPage from "./../../assets/lessonPage.png";
import Record from "./../../assets/record/original.png";
import Mic from "./../../assets/mic.png";
import Ear from "../../assets/ear.png";
import Play from "../../assets/play.png";
import Pause from "../../assets/pause.png";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import SelectLanguage from "../SelectLanguage";

const colorStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      "&:hover": { borderColor: "#1B535C", boxShadow: "0 0 0 1px #1B535C" },
      backgroundColor: "#F3F2F2",
      height: "64px",
      maxWidth: "100%",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0 0 0 1px #1B535C" : "none",
      borderColor: state.isFocused ? "#1B535C" : "transparent",
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#F3F2F2",
    marginTop: "12px",
    borderRadius: 10,
    border: "1px solid #1B535C",
    color: "#373B3B",
    padding: 0,
    overflow: "hidden",
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      color: "#373B3B",
      "&:active": { backgroundColor: "#adadad" },
      backgroundColor:
        state.isFocussed || state.isSelected ? "#adadad" : "#F3F2F2",
      margin: 0,
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, color: "#818383", fontSize: "16px" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};

const LessonPageComponent = (props) => {
  useEffect(() => {
    window.replaceWord = function (event) {
      let replaceData = event.target.getAttribute("data-replace");
      let data = event.target.innerText;
      let element = document.getElementById(replaceData);
      element.outerHTML = data;
      document.getElementById("modalBox").outerHTML = null;
      document.getElementById("backdrop").outerHTML = "";
    };
    window.ignoreThisWord = function (event) {
      let replaceData = event.target.getAttribute("data-replace");
      let data = replaceData;
      let element = document.getElementById(replaceData);
      element.outerHTML = data;
      document.getElementById("modalBox").outerHTML = null;
      document.getElementById("backdrop").outerHTML = "";
    };
  }, []);
  useEffect(() => {
    window.bgChange = function (x) {
      x.style.background = "#dbecf3";
    };
    window.bgWhite = function (x) {
      x.style.background = "#fff";
    };
  });

  useEffect(() => {
    window.showmodal = function (event) {
      const element = event.target.innerText;

      const data = JSON.parse(event.target.dataset.info);

      var backdropdiv = document.createElement("div");
      backdropdiv.setAttribute("id", "backdrop");
      backdropdiv.style.position = "fixed";
      backdropdiv.style.top = "0px";
      backdropdiv.style.left = "0px";
      backdropdiv.style.right = "0px";
      backdropdiv.style.bottom = "0px";
      backdropdiv.addEventListener("click", function () {
        document.getElementById("backdrop").outerHTML = "";
        document.getElementById("modalBox").outerHTML = "";
      });
      document.body.append(backdropdiv);
      var div = document.createElement("div");
      div.setAttribute("id", "modalBox");
      div.style.width = "300px";
      div.style.position = "absolute";
      div.style.left = window.jQuery(event.target).offset().left - 1 + "px";
      div.style.top = window.jQuery(event.target).offset().top + 20 + "px";
      div.style.background = "#fff";
      div.style.border = "1px solid #dad2d2";
      div.style.boxShadow = "0 1px 6px 0 rgba(0, 0, 0, 0.2)";
      let suggestion = data.suggestions.split("#");

      let output =
        "<p  id='word' style='border-bottom:1px solid rgb(232 232 232);color:#373739;padding:5px 10px;font-size:14px;margin:0px;background-color:#EEE;font-weight:600;cursor:default;'>" +
        data.description +
        "</p>";

      suggestion.map((data) => {
        output +=
          `<p onmouseover="window.bgChange(this)" onmouseout="window.bgWhite(this)" style='border-bottom:1px solid rgb(247 247 247);color:#7f7f7f;padding:5px 10px;font-size:14px;margin:0px;cursor:default' data-replace=${element} onclick='window.replaceWord(event)'>` +
          data +
          "</p>";
      });
      output +=
        `<p onmouseover="window.bgChange(this)" onmouseout="window.bgWhite(this)" style='border-bottom:1px solid rgb(247 247 247);color:#7f7f7f;padding:5px 10px;font-size:14px;margin:0px;cursor:default;' data-replace=${element} onclick='window.ignoreThisWord(event)'>` +
        "Ignore error for this word" +
        "</p>";
      div.innerHTML = output;
      document.body.append(div);
    };
  }, []);

  async function doit(e) {
    if (document.getElementById("modalBox")) {
      return;
    }
    e.preventDefault();
    let element = e.target;
    let data = element.innerText;

    let response = await axios.post(
      "/api/v1/languagetool",
      {
        text: data,
        language: grammar,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    const json = response.data.data;
    var incompleteResults = json.warnings && json.warnings.incompleteResults;
    var incompleteResultsReason =
      json.warnings && json.warnings.incompleteResults
        ? json.warnings.incompleteResultsReason
        : null;
    var suggestions = [];
    for (var key in json.matches) {
      var match = json.matches[key];
      var suggestion = {};
      suggestion["description"] = match.message;
      suggestion["suggestions"] = [];
      var msuggestions = [];
      for (var k = 0; k < match.replacements.length; k++) {
        var repl = match.replacements[k];
        msuggestions.push(repl.value);
      }
      suggestion["suggestions"] = msuggestions.join("#");
      suggestion["sentence"] = match.sentence;
      suggestion["offset"] = match.offset;
      suggestion["errorlength"] = match.length;
      suggestion["type"] = match.rule.category.name;
      suggestion["typeid"] = match.rule.category.id;
      suggestion["typeName"] =
        match.type && match.type.typeName ? match.type.typeName : "";
      suggestion["ruleid"] = match.rule.id;
      suggestion["subid"] = match.rule.subId;
      suggestion["its20type"] = match.rule.issueType;
      var urls = match.rule.urls;
      if (urls && urls.length > 0) {
        if (urls[0].value) {
          suggestion["moreinfo"] = urls[0].value;
        } else {
          suggestion["moreinfo"] = urls[0]; //TODO: remove this case, it's for an old API version
        }
      }
      suggestions.push(suggestion);
    }

    var ignoredRulesIds = [];
    var surrogateAttributeDelimiter = "---#---";
    var surrogateAttribute = "onkeypress";
    var ignoredSpellingErrors = [];

    var textWithCursor = getPlainTextWithCursorMarker(data);
    var cursorPos = textWithCursor.indexOf("\ufeff");
    var newText = getPlainText(data);

    newText = newText.replace(/</g, "\ue001");
    newText = newText.replace(/>/g, "\ue002");

    var previousSpanStart = -1;
    // iterate backwards as we change the text and thus modify positions:
    for (
      var suggestionIndex = suggestions.length - 1;
      suggestionIndex >= 0;
      suggestionIndex--
    ) {
      var suggestion = suggestions[suggestionIndex];
      if (!suggestion.used) {
        var spanStart = suggestion.offset;
        var spanEnd = spanStart + suggestion.errorlength;
        if (previousSpanStart !== -1 && spanEnd > previousSpanStart) {
          // overlapping errors - these are not supported by our underline approach,
          // as we would need overlapping <span>s for that, so skip the error:
          continue;
        }
        previousSpanStart = spanStart;

        var ruleId = suggestion.ruleid;
        if (ignoredRulesIds.indexOf(ruleId) !== -1) {
          continue;
        }

        var cssName;
        if (suggestion.typeName === "Hint") {
          cssName = "hiddenSuggestion";
        } else if (
          suggestion.typeid === "DIFFICULT_WORDS" /* DE (Leichte Sprache) */ ||
          suggestion.typeName === "UnknownWord" ||
          ruleId.indexOf("SPELLER_RULE") >= 0 ||
          ruleId.indexOf("MORFOLOGIK_RULE") === 0 ||
          ruleId === "HUNSPELL_NO_SUGGEST_RULE" ||
          ruleId === "HUNSPELL_RULE" ||
          ruleId === "FR_SPELLING_RULE"
        ) {
          cssName = "hiddenSpellError";
        } else if (
          suggestion.its20type === "style" ||
          suggestion.its20type === "locale-violation" ||
          suggestion.its20type === "register"
        ) {
          cssName = "hiddenSuggestion";
        } else {
          cssName = "hiddenGrammarError";
        }
        var delim = surrogateAttributeDelimiter;
        var coveredText = newText.substring(spanStart, spanEnd);
        if (ignoredSpellingErrors.indexOf(coveredText) !== -1) {
          continue;
        }
        var metaInfo =
          ruleId +
          delim +
          suggestion.subid +
          delim +
          suggestion.description +
          delim +
          suggestion.sentence +
          delim +
          suggestion.suggestions +
          delim +
          coveredText;
        if (suggestion.moreinfo) {
          metaInfo += delim + suggestion.moreinfo;
        }
        metaInfo = metaInfo
          .replace(/&/g, "&amp;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&apos;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;"); // escape HTML

        var idName = newText.substring(spanStart, spanEnd);

        newText =
          newText.substring(0, spanStart) +
          "<span data-info='" +
          JSON.stringify(suggestion) +
          "' " +
          surrogateAttribute +
          '="' +
          metaInfo +
          '"id="' +
          idName +
          '" class="' +
          cssName +
          '"onclick="' +
          "window.showmodal(event)" +
          '">' +
          newText.substring(spanStart, spanEnd) +
          "</span>" +
          newText.substring(spanEnd);
        suggestion.used = true;
      }
    }

    // now insert a span into the location of the original cursor position,
    // only considering real text content of course:
    newText = _insertCursorSpan(newText, cursorPos);

    newText = newText.replace(/^\n/, "");
    newText = newText.replace(/^\n/, "");
    newText = newText.replace(/\n/g, "<br/>");
    newText = newText.replace(/\ue001/g, "&lt;");
    newText = newText.replace(/\ue002/g, "&gt;");

    element.innerHTML = newText;

    return;
  }

  const _insertCursorSpan = function (text, cursorPos) {
    var newTextParts = text.split(/([<>])/);
    var inTag = 0;
    var textPos = 0;
    var stringPos = 0;
    for (var i = 0; i < newTextParts.length; i++) {
      if (newTextParts[i] === "<" || newTextParts[i] === ">") {
        if (newTextParts[i] === "<") {
          inTag++;
        } else {
          inTag--;
        }
      } else if (inTag === 0) {
        var partLength = newTextParts[i].length;
        if (cursorPos >= textPos && cursorPos <= textPos + partLength) {
          var relativePos = cursorPos - textPos;
          text = text.insert(
            stringPos + relativePos,
            "<span id='caret_pos_holder'></span>"
          );
          break;
        }
        textPos += partLength;
      }
      stringPos += newTextParts[i].length;
    }
    return text;
  };

  const getPlainTextWithCursorMarker = function (text) {
    return _getPlainText(text, false);
  };

  const getPlainText = function (text) {
    return _getPlainText(text, true);
  };

  const _getPlainText = function (text, removeCursor) {
    var plainText = text
      .replace(/^<p>/, "")
      .replace(/<p>/g, "\n\n")
      .replace(/<br>/g, "\n")
      .replace(/<br\s*\/>/g, "\n")
      .replace(/<.*?>/g, "")
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&nbsp;/g, " "); // see issue #10
    if (removeCursor) {
      plainText = plainText.replace(/\ufeff/g, ""); // feff = 65279 = cursor code
    }
    return plainText;
  };

  useEffect(() => {
    getClasses();
  }, []);
  const [grammar, setGrammar] = useState("");
  const [apiInProgress, setAPIINProgress] = useState(false);
  const [classesOptions, setClassesOptions] = useState();
  const [name, setName] = useState();
  const [lessonsOptions, setLessonsOptions] = useState();
  const [lessonId, setLessonId] = useState();
  const [words, _setWords] = useState([]);
  const [language, setLanguage] = useState(null);
  const [gender, setGender] = useState(null);
  const wordsStateRef = React.useRef(words);
  const [languageId, setLanguageId] = useState(null);
  const history = useHistory();

  const setWords = (data) => {
    wordsStateRef.current = data;
    _setWords(data);
  };

  const getClasses = async () => {
    let res = await axios.get("/api/v1/classes", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.data.data.classes) {
      setClassesOptions(
        res.data.data.classes.map((obj) => {
          return {
            value: obj.id,
            label: obj.name,
            language: obj.language,
          };
        })
      );
      setName(res.data.data.school.name);
    }
  };

  const fetchLessons = async (value) => {
    setWords([]);
    let text = "";
    switch (value.language.key) {
      case "cn":
        text = "zh";
        break;
      case "en":
        text = "en-US";
        break;
      case "sp":
        text = "es";
        break;
      default:
        console.log("Wrong choice");
    }
    setGrammar(text);
    setLanguageId(value.language.id);
    setLanguage(null);
    let res = await axios.get(`/api/v1/lessons?class_id=${value.value}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (res.data.data.lessons) {
      setLessonsOptions(
        res.data.data.lessons.map((obj) => {
          return {
            value: obj.id,
            label: obj.name,
          };
        })
      );
    }
  };

  const listenAudio = async (value) => {
    if (value) {
      let res = await axios.get(
        `/api/v1/audio?word=${value}&languageId=${languageId}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: `bearer ${localStorage.getItem("accessToken")}`,
          },
          responseType: "blob",
        }
      );
      if (res.data) {
        var url = window.URL.createObjectURL(res.data);
        const audio = new Audio(url);
        audio.play();
      }
    } else {
      alert("Please enter text");
    }
  };

  const fetchWords = async (value) => {
    setLanguage(value);
    setWords([]);
    setLessonId(value.value);
    let res = await axios.get(`/api/v1/wordlist?lesson_id=${value.value}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (res.data.data.words) {
      setWords(
        res.data.data.words.map((obj) => {
          return {
            name: obj.text,
            id: obj.id,
            isRecording: false,
            isRecorded: false,
            phrase: null,
            audio: null,
            duration: "00:00:00",
            audioBlob: null,
          };
        })
      );
    }
  };

  function startRecording(index) {
    if (words[index].phrase) {
      let items = [...words];
      let item = { ...items[index] };
      item.isRecording = true;
      items[index] = item;
      setWords(items);
      try {
        if (window.recorderInstance) {
          window.recorderInstance.clear();
          window.recorderInstance = null;
        }
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        navigator.getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia;
        window.URL = window.URL || window.webkitURL;
        var audio_context = new AudioContext({ sampleRate: 16000 });
        var constraints = { audio: true, video: false };
        if (navigator.userAgent.indexOf("Chrome") !== -1) {
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function (stream) {
              var input = audio_context.createMediaStreamSource(stream);
              var recorder = new window.Recorder(input);
              recorder.record();
              window.recorderInstance = recorder;
            })
            .catch(function (err) {
              alert(err);
            });
        } else {
          navigator.getUserMedia(constraints, function (stream) {
            var input = audio_context.createMediaStreamSource(stream);
            var recorder = new window.Recorder(input);
            recorder.record();
            window.recorderInstance = recorder;
          });
        }
      } catch (e) {
        alert("No web audio support in this browser!");
      }
    } else {
      alert(
        props.intl.formatMessage({
          id: "completeSentenceMessage",
          defaultMessage: "Oops! Please remember to complete all sentences.",
        })
      );
    }
  }

  function stopRecording(index) {
    let items = [...words];
    let item = { ...items[index] };
    item.isRecording = false;
    item.isRecorded = true;
    items[index] = item;
    setWords(items);
    window.recorderInstance.stop();
    window.recorderInstance.exportWAV(function (audioBlob) {
      window.audioBlob = audioBlob;
      var audio = new Audio(URL.createObjectURL(audioBlob));
      audio.dataset.id = index;
      audio.addEventListener("timeupdate", (event) => {
        let items = [...wordsStateRef.current];
        let item = { ...items[index] };
        item.isRecording = true;
        item.isRecorded = true;
        var duration = new Date(Math.round(event.target.currentTime) * 1000)
          .toUTCString()
          .match(/(\d\d:\d\d:\d\d)/)[0];
        item.duration = duration;
        items[index] = item;
        setWords(items);
      });

      audio.addEventListener("ended", function (event) {
        if (audio.ended) {
          let items = [...wordsStateRef.current];
          let item = { ...items[index] };
          item.isRecording = false;
          item.isRecorded = true;
          items[index] = item;
          setWords(items);
        }
      });
      let items = [...words];
      let item = { ...items[index] };
      item.audio = audio;
      item.audioBlob = audioBlob;
      item.isRecording = false;
      item.isRecorded = true;
      items[index] = item;
      setWords(items);
    });
  }

  function playAudio(index) {
    let items = [...words];
    let item = { ...items[index] };
    item.isRecorded = true;
    item.isRecording = true;
    items[index] = item;
    setWords(items);
    item.audio.play();
  }

  function stopAudio(index) {
    let items = [...words];
    let item = { ...items[index] };
    item.isRecorded = true;
    item.isRecording = false;
    items[index] = item;
    setWords(items);
    item.audio.pause();
  }

  function retry(index) {
    let items = [...words];
    let item = { ...items[index] };
    item.isRecorded = false;
    item.audio = null;
    item.isRecording = false;
    item.duration = "00:00:00";
    items[index] = item;
    setWords(items);
  }

  const handleLogout = async () => {
    let res = await axios.post("/api/v1/logout", null, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.data.success) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userID");
      history.push("/");
    }
  };

  const enableAudio = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(function (stream) {
        alert("Audio enabled");
      })
      .catch(function (err) {
        alert("Audio disabled");
      });
  };

  const handleSubmit = async () => {
    try {
      if (gender) {
        let formData = new FormData();
        words.forEach((word, index) => {
          if (word.audio) {
            formData.append(`lesson[${index}]`, lessonId);
            formData.append(`wordlist_id[${index}]`, word.id);
            formData.append(
              `fileName[${index}]`,
              (
                word && word.phrase.replace(/ /g, "_") + "-word.wav"
              ).toLowerCase()
            );
            formData.append(`audio[${index}]`, word.audioBlob);
            formData.append(`phrase[${index}]`, word.phrase);
            formData.append(`gender[${index}]`, gender);
          }
        });
        setAPIINProgress(true);
        let res = await axios.post("/api/v1/recording", formData, {
          headers: {
            authorization: `bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        setAPIINProgress(false);
        if (res.data.success) {
          history.push(`/result/${res.data.data.resultId}`);
        } else {
          alert(res.data.message);
        }
      } else {
        alert(
          props.intl.formatMessage({
            id: "genderMessage",
            defaultMessage: "Select gender",
          })
        );
      }
    } catch (err) {
      setAPIINProgress(false);
      console.log(err);
    }
  };
  return (
    <>
      <Container className={Styles.container}>
        <Row>
          <SelectLanguage setTextLanguage={props.setTextLanguage} />
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <h1 className={Styles.logoName} onClick={() => handleLogout()}>
              Voiceme
            </h1>
          </Col>
          <Col md={6} sm={12}>
            <p className={Styles.schoolOptions}>{name}</p>
          </Col>
        </Row>
        <img src={LessonPage} alt="Logo" className={Styles.image} />
        <Row style={{ marginTop: "44px" }}>
          <Col sm="7">
            <p className={Styles.text}>
              <FormattedMessage id="hiiMessage" defaultMessage={`Hi`} /> ,
              {localStorage.getItem("userID")}
              <span className={Styles.subText} onClick={() => handleLogout()}>
                <FormattedMessage
                  id="logOutMessage"
                  defaultMessage={`Log out`}
                />
              </span>
            </p>
          </Col>
          <Col sm="3">
            <Row>
              <Col style={{ display: "flex" }}>
                <Form.Check className={Styles.formCheck}>
                  <Form.Check.Input
                    className="d-none"
                    id="male"
                    value={gender === "male"}
                    name="gender"
                    type="radio"
                    onChange={() => setGender("male")}
                  />
                </Form.Check>
                <div
                  className={Styles.genderDiv}
                  onClick={() => setGender("male")}
                >
                  <div
                    className={
                      gender === "male"
                        ? Styles.checkedGenderDiv
                        : Styles.genderDiv
                    }
                  ></div>
                </div>
                <Form.Label className={Styles.checkboxLabel}>
                  <FormattedMessage
                    id="maleGenderMessage"
                    defaultMessage={"Male"}
                  />
                </Form.Label>
              </Col>
              <Col style={{ display: "flex" }}>
                <Form.Check className={Styles.formCheck}>
                  <Form.Check.Input
                    className="d-none"
                    id="female"
                    name="gender"
                    type="radio"
                    onChange={() => setGender("female")}
                  />
                </Form.Check>
                <div
                  className={Styles.genderDiv}
                  onClick={() => setGender("female")}
                >
                  <div
                    className={
                      gender === "female"
                        ? Styles.checkedGenderDiv
                        : Styles.genderDiv
                    }
                  ></div>
                </div>
                <Form.Label className={Styles.checkboxLabel}>
                  <Form.Label className={Styles.checkboxLabel}>
                    <FormattedMessage
                      id="femaleGenderMessage"
                      defaultMessage={"Female"}
                    />
                  </Form.Label>
                </Form.Label>
              </Col>
            </Row>
          </Col>

          <Col
            sm="2"
            style={{ marginTop: "4px", cursor: "pointer" }}
            onClick={() => enableAudio()}
          >
            <img src={Mic} alt="mic" />
            <span className={Styles.subText}>
              <FormattedMessage
                id="microphoneMessage"
                defaultMessage={"Enable Microphone"}
              />
            </span>
          </Col>
          <Col sm="12">
            <p
              style={{
                fontSize: "15px",
                textAlign: "right",
              }}
            >
              <FormattedMessage
                id="toggleMessage"
                defaultMessage=" Remember to toggle your gender and check microphone permission"
              />
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={12}>
            <Select
              options={classesOptions}
              className={Styles.selectedOptions}
              placeholder={
                <FormattedMessage
                  id="selectClassMessage"
                  defaultMessage="Select your class"
                />
              }
              styles={colorStyles}
              onChange={(e) => fetchLessons(e)}
            />
          </Col>
          <Col md={4} sm={12}>
            <Select
              options={lessonsOptions}
              className={Styles.selectedOptions}
              placeholder={
                <FormattedMessage
                  id="selectLessonMessage"
                  defaultMessage="Select your lesson"
                />
              }
              styles={colorStyles}
              value={language}
              onChange={(e) => fetchWords(e)}
            />
          </Col>
        </Row>
        {words &&
          words.map((word, index) => {
            return (
              <Card className={Styles.card} key={index}>
                <Card.Body>
                  <div style={{ display: "flex" }}>
                    <p className={Styles.sentence}>{word.name}</p>
                    <img
                      onClick={() => listenAudio(word.name)}
                      src={Ear}
                      alt="ear"
                      style={{
                        width: 20,
                        height: 20,
                        margin: "1.4%",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <Row>
                    <div
                      style={{
                        width: "80%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <FormattedMessage
                        id="sentenceMessage"
                        defaultMessage="Write your sentence here"
                      >
                        {(placeholder) => (
                          <div
                            className={`${Styles.input} ${Styles.adminInput} myeditablediv`}
                            id={`checktext${index}`}
                            name={`text${index}`}
                            type="text"
                            style={{}}
                            onInput={(e) => {
                              word.phrase = e.target.innerText;
                            }}
                            onBlur={doit}
                            contentEditable
                            placeholder={placeholder}
                          ></div>
                        )}
                      </FormattedMessage>

                      <img
                        alt="ear"
                        src={Ear}
                        className={`${Styles.earButton}`}
                        onClick={() => listenAudio(word.phrase)}
                      />
                    </div>

                    {word.isRecording ? (
                      word.isRecorded ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              marginLeft: "2%",
                              marginTop: "-2%",
                            }}
                          >
                            {word.duration}
                            <div
                              className={Styles.record}
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                stopAudio(index);
                              }}
                            >
                              <img
                                alt="pause"
                                className={Styles.record}
                                src={Pause}
                              />
                            </div>
                          </div>
                          <p
                            className={`${Styles.subText} ${Styles.retry}`}
                            onClick={() => {
                              retry(index);
                            }}
                          >
                            <FormattedMessage
                              id="retryMessage"
                              defaultMessage="Re-try"
                            />
                          </p>
                        </>
                      ) : (
                        <>
                          <div
                            className={Styles.record}
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              stopRecording(index);
                            }}
                          >
                            <img
                              alt="record"
                              className={Styles.record_animate}
                              src={Record}
                            />
                          </div>
                        </>
                      )
                    ) : word.isRecorded ? (
                      <>
                        <div
                          className={Styles.record}
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            playAudio(index);
                          }}
                        >
                          <img
                            alt="play"
                            className={Styles.record}
                            src={Play}
                          />
                        </div>
                        <p
                          className={`${Styles.subText} ${Styles.retry}`}
                          onClick={() => {
                            retry(index);
                          }}
                        >
                          <FormattedMessage
                            id="retryMessage"
                            defaultMessage="Re-try"
                          />
                        </p>
                      </>
                    ) : (
                      <div
                        className={Styles.record}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          startRecording(index);
                        }}
                      >
                        <img
                          alt="record"
                          className={Styles.record}
                          src={Record}
                        />
                      </div>
                    )}
                  </Row>
                </Card.Body>
              </Card>
            );
          })}

        {words && words.length > 0 && (
          <Button
            variant="primary"
            size="lg"
            className={Styles.btn}
            active
            onClick={() => {
              if (apiInProgress) {
                return;
              }
              handleSubmit();
            }}
          >
            <FormattedMessage
              id="scoreButtonMessage"
              defaultMessage="Check my speaking scores"
            />
          </Button>
        )}
        <p className={Styles.footer}>
          All copyrights reserved. Voiceme powered by{" "}
          <a
            href="https://www.aispeak.ai"
            target="_blank"
            rel="noopener noreferrer"
            className={Styles.footerSpan}
          >
            aispeak
          </a>{" "}
          technology and{" "}
          <a
            href="https://languagetool.org/"
            target="_blank"
            rel="noopener noreferrer"
            className={Styles.footerSpan}
          >
            languagetool
          </a>{" "}
        </p>
      </Container>
    </>
  );
};

export default injectIntl(LessonPageComponent);
