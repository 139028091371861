import React, { useState, useEffect } from "react";
import Styles from "./schoolHome.module.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Landing from "./../../assets/landing.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SelectLanguage from "../SelectLanguage";

export default (props) => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [schoolId, setSchoolId] = useState();
  const [studentNumber, setStudentNumber] = useState();
  const [currentView, setCurrentView] = useState("student");
  useEffect(() => {
    getData();
  }, []);

  const history = useHistory();
  const getData = async () => {
    let res = await axios.get(
      `/api/v1/schools/${props.match.params.schoolSlug}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.data.school) {
      setName(res.data.data.school.name);
      setSchoolId(res.data.data.school.id);
    } else history.push("/");
  };

  const handleSubmit = () => {
    const data = {
      email: email,
      password: password,
      school_id: schoolId,
    };
    submitData(data);
  };

  const submitData = async (userData) => {
    let res = await axios.post("/api/v1/login", userData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (res.data.data) {
      localStorage.setItem("userID", res.data.data.user_id);
      localStorage.setItem("refreshToken", res.data.data.refreshToken);
      localStorage.setItem("accessToken", res.data.data.accessToken);
      history.push({
        pathname: "/admin-dashboard",
        state: { slug: props.match.params.schoolSlug },
      });
    } else {
      alert(res.data.message);
    }
  };

  const handleStudentSubmit = async () => {
    let res = await axios.post(
      "/api/v1/studentLogin",
      { studentNumber: studentNumber, slug: props.match.params.schoolSlug },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    if (res.data.data) {
      localStorage.setItem("userID", studentNumber);
      localStorage.setItem("refreshToken", res.data.data.refreshToken);
      localStorage.setItem("accessToken", res.data.data.accessToken);
      history.push({
        pathname: "/lesson-page",
      });
    } else {
      alert(res.data.message);
    }
  };
  return (
    <Container className={Styles.container}>
      <Row>
        <SelectLanguage setTextLanguage={props.setTextLanguage} />
      </Row>
      <Row>
        <Col md={6} sm={12}>
          <h1 className={Styles.logoName}>Voiceme</h1>
          <img className={Styles.image} src={Landing} alt="Landing" />
        </Col>
        <Col md={6} sm={12} className={Styles.columnLeft}>
          <p className={Styles.schoolOptions}>{name} </p>
          {currentView === "student" ? (
            <>
              <h2 className={`${Styles.heading1}`}>
                <FormattedMessage id="helloMessage" defaultMessage="Hello" />
              </h2>
              <h2 className={Styles.heading}>
                <FormattedMessage
                  id="helloContentMessage"
                  defaultMessage="Let's improve our language speaking skills!"
                />
              </h2>
              <br />
              <FormattedMessage
                id="studentMessage"
                defaultMessage="Enter your student number"
              >
                {(msg) => (
                  <input
                    className={Styles.input}
                    type="text"
                    placeholder={msg}
                    onChange={(e) => setStudentNumber(e.target.value)}
                  />
                )}
              </FormattedMessage>

              <Row>
                <Col xs={12}>
                  <Button
                    variant="primary"
                    size="lg"
                    className={Styles.btn}
                    active
                    onClick={() => {
                      handleStudentSubmit();
                    }}
                  >
                    <FormattedMessage
                      id="loginMessage"
                      defaultMessage="Log in"
                    />
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <h2 className={Styles.heading}>
                {" "}
                <FormattedMessage
                  id="forAdministratorsMessage"
                  defaultMessage="Teachers Log-in"
                />
              </h2>
              <br></br>
              <Row>
                <Col xs={12}>
                  <FormattedMessage
                    id="userNameMessage"
                    defaultMessage="Username"
                  >
                    {(msg) => (
                      <input
                        className={`${Styles.input} ${Styles.adminInput}`}
                        type="text"
                        placeholder={msg}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    )}
                  </FormattedMessage>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col xs={12}>
                  <FormattedMessage
                    id="passwordMessage"
                    defaultMessage="Password"
                  >
                    {(msg) => (
                      <input
                        className={`${Styles.input} ${Styles.adminInput}`}
                        type="password"
                        placeholder={msg}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    )}
                  </FormattedMessage>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button
                    variant="primary"
                    size="lg"
                    className={Styles.btn}
                    active
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    <FormattedMessage
                      id="loginMessage"
                      defaultMessage="Log in"
                    />
                  </Button>
                </Col>
              </Row>
            </>
          )}
          <div className={Styles.toggleDiv}>
            <p
              className={Styles.toggleOption}
              onClick={() => {
                setCurrentView("student");
              }}
            >
              <FormattedMessage
                id="studentLinkMessage"
                defaultMessage={`Student Click Here`}
              />
            </p>
            <p
              className={Styles.toggleOption}
              onClick={() => {
                setCurrentView("faculty");
              }}
            >
              <FormattedMessage
                id="teacherLinkMessage"
                defaultMessage={`Teacher Click Here`}
              />
            </p>
          </div>
        </Col>
      </Row>
      <p className={Styles.footer}>
        All copyrights reserved. Voiceme powered by{" "}
        <a
          href="https://www.aispeak.ai"
          target="_blank"
          rel="noopener noreferrer"
          className={Styles.footerSpan}
        >
          aispeak
        </a>{" "}
        technology and{" "}
        <a
          href="https://languagetool.org/"
          target="_blank"
          rel="noopener noreferrer"
          className={Styles.footerSpan}
        >
          languagetool
        </a>{" "}
      </p>
    </Container>
  );
};
