import React, { useEffect, useState } from "react";
import Styles from "./result.module.css";
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap";
import Select from "react-select";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Mic from "./../../assets/mic.png";
import Ear from "./../../assets/ear.png";
import Play from "./../../assets/play.png";
import Pause from "./../../assets/pause.png";
import Rise from "./../../assets/rise.svg";
import Fall from "./../../assets/fall.svg";
import BadTone1 from "./../../assets/chinese_tone_marks/tone1_bad.svg";
import GoodTone1 from "./../../assets/chinese_tone_marks/tone1_good.svg";
import OkTone1 from "./../../assets/chinese_tone_marks/tone1_ok.svg";
import BadTone2 from "./../../assets/chinese_tone_marks/tone2_bad.svg";
import GoodTone2 from "./../../assets/chinese_tone_marks/tone2_good.svg";
import OkTone2 from "./../../assets/chinese_tone_marks/tone2_ok.svg";
import BadTone3 from "./../../assets/chinese_tone_marks/tone3_bad.svg";
import GoodTone3 from "./../../assets/chinese_tone_marks/tone3_good.svg";
import OkTone3 from "./../../assets/chinese_tone_marks/tone3_ok.svg";
import BadTone4 from "./../../assets/chinese_tone_marks/tone4_bad.svg";
import GoodTone4 from "./../../assets/chinese_tone_marks/tone4_good.svg";
import OkTone4 from "./../../assets/chinese_tone_marks/tone4_ok.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAudio } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SelectLanguage from "../SelectLanguage";

const colorStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      "&:hover": { borderColor: "#1B535C", boxShadow: "0 0 0 1px #1B535C" },
      backgroundColor: "#F3F2F2",
      height: "64px",
      width: "255px",
      borderRadius: "10px",
      boxShadow: state.isFocused ? "0 0 0 1px #1B535C" : "none",
      borderColor: state.isFocused ? "#1B535C" : "transparent",
    };
  },
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#F3F2F2",
    marginTop: "12px",
    borderRadius: 10,
    border: "1px solid #1B535C",
    color: "#373B3B",
    padding: 0,
    overflow: "hidden",
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      color: "#373B3B",
      "&:active": { backgroundColor: "#adadad" },
      backgroundColor:
        state.isFocussed || state.isSelected ? "#adadad" : "#F3F2F2",
      margin: 0,
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles, color: "#818383", fontSize: "16px" }),
  singleValue: (styles, { data }) => ({ ...styles }),
};

export default (props) => {
  const history = useHistory();
  const [name, setName] = useState(null);
  const [results, _setResults] = useState([]);
  const [completeness, setCompleteness] = useState(0);
  const [fluency, setFluency] = useState(0);
  const [pronunciation, setPronunciation] = useState(0);
  const [lessonsOptions, setLessonsOptions] = useState([]);
  const resultsStateRef = React.useRef(results);
  const [gender, setGender] = useState(null);
  const [languageId, setLanguageId] = useState(null);
  const [language, setLanguage] = useState(null);
  const [overall, setOverall] = useState(0);
  const [tone, setTone] = useState(0);
  const [file, setFile] = useState(null);
  useEffect(() => {
    getResult();
    handleLessons();
  }, []);

  const setResults = (data) => {
    resultsStateRef.current = data;
    _setResults(data);
  };

  const handleLessons = async () => {
    let res = await axios.get(`/api/v1/all_lessons`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (res.data.data.lessons) {
      setLessonsOptions(
        res.data.data.lessons.map((obj) => {
          return {
            value: obj.id,
            label: obj.name,
          };
        })
      );
    }
  };

  const handleLogout = async () => {
    let res = await axios.post("/api/v1/logout", null, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.data.success) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userID");
      history.push("/");
    }
  };

  const getResult = async () => {
    let res = await axios.get(`/api/v1/result/${props.match.params.id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    if (res.data.success) {
      setLanguageId(res.data.data.language.id);
      setLanguage(res.data.data.language.key);
      setName(res.data.data.school.name);
      setGender(res.data.data.result.gender);
      setFile(res.data.data.fileName);
      await calculateResult(res.data.data.results);
    } else {
      handleLogout();
    }
  };

  const calculateResult = async (data) => {
    let final = [];
    let completeness = 0;
    let fluency = 0;
    let pronunciation = 0;
    let tone = 0;
    let overall = 0;
    data.forEach((wordResult) => {
      completeness = completeness + parseInt(wordResult.completeness);
      fluency = fluency + parseInt(wordResult.fluency);
      pronunciation = pronunciation + parseInt(wordResult.pronunciation);
      tone =
        tone +
        parseInt(
          JSON.parse(wordResult.result_json).tone &&
            JSON.parse(wordResult.result_json).tone
        );
      overall =
        overall +
        parseInt(
          JSON.parse(wordResult.result_json).overall &&
            JSON.parse(wordResult.result_json).overall
        );
      final.push({
        text: wordResult.text,
        pronunciation: wordResult.pronunciation,
        fluency: wordResult.fluency,
        score: wordResult.score,
        stress: wordResult.stress,
        resultJSON: wordResult.result_json,
        intonation: wordResult.intonation,
        audio: new Audio(wordResult.s3_location),
        audioLink: wordResult.s3_location,
        isPlaying: false,
        duration: "00:00:00",
      });
    });
    setCompleteness(parseInt(completeness / data.length));
    setFluency(parseInt(fluency / data.length));
    setPronunciation(parseInt(pronunciation / data.length));
    setOverall(parseInt(overall / data.length));
    setTone(parseInt(tone / data.length));
    setResults(final);
  };

  const playAudio = (index) => {
    let items = [...results];
    let item = { ...items[index] };
    item.isPlaying = true;
    items[index] = item;
    setResults(items);
    item.audio.play();
    item.audio.addEventListener("ended", function (event) {
      if (item.audio.ended) {
        let items = [...resultsStateRef.current];
        let item = { ...items[index] };
        item.isPlaying = false;
        items[index] = item;
        setResults(items);
      }
    });
    item.audio.addEventListener("timeupdate", (event) => {
      let items = [...resultsStateRef.current];
      let item = { ...items[index] };
      var duration = new Date(Math.round(event.target.currentTime) * 1000)
        .toUTCString()
        .match(/(\d\d:\d\d:\d\d)/)[0];
      item.duration = duration;
      items[index] = item;
      setResults(items);
    });
  };

  const pauseAudio = (index) => {
    let items = [...results];
    let item = { ...items[index] };
    item.isPlaying = false;
    items[index] = item;
    setResults(items);
    item.audio.pause();
  };

  const listenAudio = async (value) => {
    let res = await axios.get(
      `/api/v1/audio?word=${value}&languageId=${languageId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: `bearer ${localStorage.getItem("accessToken")}`,
        },
        responseType: "blob",
      }
    );
    var url = window.URL.createObjectURL(res.data);
    const audio = new Audio(url);
    audio.play();
  };

  const generatePdf = async () => {
    let res = await axios.get(`/api/v1/report/${props.match.params.id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: `bearer ${localStorage.getItem("accessToken")}`,
      },
      responseType: "blob",
    });
    if (res.data) {
      var url = window.URL.createObjectURL(res.data);
      var a = document.createElement("a");
      a.href = url;
      a.download = `${file.studentNumber}_${file.className}_${file.lessonName}.pdf`;
      a.click();
      a.remove();
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    }
  };

  return (
    <div>
      <Container className={Styles.container}>
        <Row>
          <SelectLanguage setTextLanguage={props.setTextLanguage} />
        </Row>
        <Row>
          <Col md={6} sm={12}>
            <h1 className={Styles.logoName} onClick={() => history.goBack()}>
              Voiceme
            </h1>
          </Col>
          <Col md={6} sm={12}>
            <p className={Styles.schoolOptions}>{name}</p>
          </Col>
        </Row>
        <Row style={{ marginTop: "44px" }}>
          <Col sm="7">
            <p className={Styles.text}>
              <FormattedMessage id="hiiMessage" defaultMessage={`Hi`} />,{" "}
              {localStorage.getItem("userID")}{" "}
              <span className={Styles.subText} onClick={() => handleLogout()}>
                <FormattedMessage
                  id="logOutMessage"
                  defaultMessage={`Log out`}
                />
              </span>
            </p>
          </Col>
          <Col sm="3">
            <Row>
              <Col style={{ display: "flex" }}>
                <Form.Check className={Styles.formCheck}>
                  <Form.Check.Input
                    className="d-none"
                    id="male"
                    value={gender}
                    name="gender"
                    type="radio"
                    checked={gender === "male"}
                  />
                  <div>
                    <div></div>
                  </div>
                </Form.Check>
                <Form.Label htmlFor="male" className={Styles.checkboxLabel}>
                  <FormattedMessage
                    id="maleGenderMessage"
                    defaultMessage={"Male"}
                  />
                </Form.Label>
              </Col>

              <Col style={{ display: "flex" }}>
                <Form.Check className={Styles.formCheck}>
                  <Form.Check.Input
                    className="d-none"
                    id="female"
                    name="gender"
                    value={gender}
                    type="radio"
                    checked={gender === "female"}
                  />
                  <div>
                    <div></div>
                  </div>
                </Form.Check>
                <Form.Label htmlFor="female" className={Styles.checkboxLabel}>
                  <FormattedMessage
                    id="femaleGenderMessage"
                    defaultMessage={"Female"}
                  />
                </Form.Label>
              </Col>
            </Row>
          </Col>
          <Col sm="2" style={{ marginTop: "4px" }}>
            <img src={Mic} alt="mic" />
            <span className={Styles.subText}>
              <FormattedMessage
                id="microphoneMessage"
                defaultMessage={"Enable Microphone"}
              />
            </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <Select
              options={lessonsOptions}
              className={Styles.selectedOptions}
              onChange={() => history.goBack()}
              placeholder={
                <FormattedMessage
                  id="lessonsMessage"
                  defaultMessage="Lessons"
                />
              }
              styles={colorStyles}
            />
          </Col>
        </Row>
        <Card className={Styles.card}>
          <Card.Body>
            <p className={Styles.score}>
              <FormattedMessage id="overallMessage" defaultMessage="Overall" />{" "}
              <FormattedMessage id="scoreMessage" defaultMessage="Score" />
            </p>
            <Row style={{ display: "flex" }}>
              <Col style={{ textAlign: "center" }}>
                <CircularProgressbar
                  className={`${Styles.progressBar}`}
                  value={completeness}
                  text={`${completeness}`}
                  styles={buildStyles({
                    textSize: "30px",
                    pathColor:
                      completeness <= 50
                        ? "red"
                        : completeness > 50 && completeness <= 70
                        ? "#F4BC1C"
                        : "green",
                    textColor: "black",
                    trailColor: "#f2f2f2",
                  })}
                />
                <h5 style={{ marginTop: "3%" }}>
                  <FormattedMessage
                    id="completenessMessage"
                    defaultMessage="Completeness"
                  />
                </h5>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <CircularProgressbar
                  className={`${Styles.progressBar}`}
                  value={pronunciation}
                  text={`${pronunciation}`}
                  styles={buildStyles({
                    textSize: "30px",
                    pathColor:
                      pronunciation <= 50
                        ? "red"
                        : pronunciation > 50 && pronunciation <= 70
                        ? "#F4BC1C"
                        : "green",
                    textColor: "black",
                    trailColor: "#f2f2f2",
                  })}
                />
                <h5 style={{ marginTop: "3%" }}>
                  <FormattedMessage
                    id="pronunciationMessage"
                    defaultMessage="Pronunciation"
                  />
                </h5>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <CircularProgressbar
                  className={`${Styles.progressBar}`}
                  value={fluency}
                  text={`${fluency}`}
                  styles={buildStyles({
                    textSize: "30px",
                    pathColor:
                      fluency <= 50
                        ? "red"
                        : fluency > 50 && fluency <= 70
                        ? "#F4BC1C"
                        : "green",
                    textColor: "black",
                    trailColor: "#f2f2f2",
                  })}
                />
                <h5 style={{ marginTop: "3%" }}>
                  <FormattedMessage
                    id="fluencyMessage"
                    defaultMessage="Fluency"
                  />
                </h5>
              </Col>
              {language === "cn" && (
                <Col style={{ textAlign: "center" }}>
                  <CircularProgressbar
                    className={`${Styles.progressBar}`}
                    value={overall}
                    text={`${overall}`}
                    styles={buildStyles({
                      textSize: "30px",
                      pathColor:
                        overall <= 50
                          ? "red"
                          : overall > 50 && overall <= 70
                          ? "#F4BC1C"
                          : "green",
                      textColor: "black",
                      trailColor: "#f2f2f2",
                    })}
                  />
                  <h5 style={{ marginTop: "3%" }}>
                    {" "}
                    <FormattedMessage
                      id="overallMessage"
                      defaultMessage="Overall"
                    />
                  </h5>
                </Col>
              )}
              {language === "cn" && (
                <Col style={{ textAlign: "center" }}>
                  <CircularProgressbar
                    className={`${Styles.progressBar}`}
                    value={tone}
                    text={`${tone}`}
                    styles={buildStyles({
                      textSize: "30px",
                      pathColor:
                        tone <= 50
                          ? "red"
                          : tone > 50 && tone <= 70
                          ? "#F4BC1C"
                          : "green",
                      textColor: "black",
                      trailColor: "#f2f2f2",
                    })}
                  />
                  <h5 style={{ marginTop: "3%" }}>
                    <FormattedMessage id="toneMessage" defaultMessage="Tone" />
                  </h5>
                </Col>
              )}
            </Row>
          </Card.Body>
        </Card>
        {results.map((result, index) => {
          let result_json = JSON.parse(result.resultJSON);
          return (
            <Card className={Styles.card} key={index}>
              <Card.Body>
                <Row style={{ display: "flex" }}>
                  <Col md={10} style={{ display: "flex", marginTop: "2%" }}>
                    <div>
                      {result_json.words.map((word, k) => {
                        let score = parseInt(word.scores.overall);
                        let tone = parseInt(word.scores.tone);
                        return (
                          <div className={Styles.scoreCard} key={k}>
                            <p className={` ${Styles.sentence}`}>
                              <p
                                style={{
                                  margin: 4,
                                  borderBottom:
                                    score <= 50
                                      ? "2px solid red"
                                      : score > 50 && score <= 70
                                      ? "2px solid #F4BC1C"
                                      : "2px solid green",
                                }}
                              >
                                {word.word}
                              </p>
                              <div className={Styles.smallCard}>
                                <span style={{ color: "#1B535C" }}>
                                  <FormattedMessage
                                    id="scoreMessage"
                                    defaultMessage="Score"
                                  />{" "}
                                </span>
                                <span
                                  style={{
                                    color:
                                      word.scores.overall <= 50
                                        ? "red"
                                        : word.scores.overall > 50 &&
                                          word.scores.overall <= 70
                                        ? "#F4BC1C"
                                        : "green",
                                  }}
                                >
                                  {word.scores.overall}
                                </span>
                                <br />
                                {/* <span style={{ color: "#1B535C" }}>Fluency </span> */}
                                {/* <span style={{ color: "#ef7777" }}>
                                {result.fluency}
                              </span> */}
                                {/* <br /> */}
                                <span style={{ color: "#1B535C" }}>
                                  <FormattedMessage
                                    id="stressMessage"
                                    defaultMessage="Stress"
                                  />{" "}
                                </span>
                                <span
                                  style={{
                                    color:
                                      word.scores.prominence <= 50
                                        ? "red"
                                        : word.scores.prominence > 50 &&
                                          word.scores.prominence <= 70
                                        ? "#F4BC1C"
                                        : "green",
                                  }}
                                >
                                  {word.scores.prominence}
                                </span>

                                {language === "cn" && (
                                  <>
                                    <br />
                                    <span style={{ color: "#1B535C" }}>
                                      <FormattedMessage
                                        id="toneMessage"
                                        defaultMessage="Tone"
                                      />{" "}
                                    </span>
                                    <span
                                      style={{
                                        color:
                                          word.scores.tone <= 50
                                            ? "red"
                                            : word.scores.tone > 50 &&
                                              word.scores.tone <= 70
                                            ? "#F4BC1C"
                                            : "green",
                                      }}
                                    >
                                      {word.scores.tone}
                                    </span>
                                  </>
                                )}
                                {/* <span style={{ color: "#1B535C" }}>
                                Intonation{" "}
                              </span> */}
                                {/* <span style={{ color: "#ef7777" }}>
                                {result.intonation}
                              </span> */}
                                <br />
                                <p>
                                  {language === "cn" ? (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          textAlign: "center",
                                        }}
                                      >
                                        {word.phonemes.map((letter) => {
                                          return letter.phone
                                            .split("")
                                            .map((character, index) => {
                                              if (
                                                index + 1 !==
                                                letter.tone_index
                                              ) {
                                                return (
                                                  <div
                                                    style={{ height: "50px" }}
                                                  >
                                                    <div
                                                      style={{
                                                        height: "10px",
                                                        marginBottom: "2px",
                                                      }}
                                                    ></div>
                                                    <span
                                                      style={{
                                                        color:
                                                          letter.pronunciation <=
                                                          50
                                                            ? "red"
                                                            : letter.pronunciation >
                                                                50 &&
                                                              letter.pronunciation <=
                                                                70
                                                            ? "#F4BC1C"
                                                            : "green",
                                                      }}
                                                    >
                                                      {character}
                                                    </span>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      height: "50px",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        height: "10px",
                                                        marginBottom: "2px",
                                                      }}
                                                    >
                                                      {word.tone ===
                                                        "tone1" && (
                                                        <>
                                                          {tone <= 50 ? (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={BadTone1}
                                                              alt="Bad Tone 1"
                                                            />
                                                          ) : tone > 50 &&
                                                            tone <= 70 ? (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={OkTone1}
                                                              alt="Ok Tone 1"
                                                            />
                                                          ) : (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={GoodTone1}
                                                              alt="Good Tone 1"
                                                            />
                                                          )}
                                                        </>
                                                      )}
                                                      {word.tone ===
                                                        "tone2" && (
                                                        <>
                                                          {tone <= 50 ? (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={BadTone2}
                                                              alt="Bad Tone 2"
                                                            />
                                                          ) : tone > 50 &&
                                                            tone <= 70 ? (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={OkTone2}
                                                              alt="Ok Tone 2"
                                                            />
                                                          ) : (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={GoodTone2}
                                                              alt="Good Tone 2"
                                                            />
                                                          )}
                                                        </>
                                                      )}
                                                      {word.tone ===
                                                        "tone3" && (
                                                        <>
                                                          {tone <= 50 ? (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={BadTone3}
                                                              alt="Bad Tone 3"
                                                            />
                                                          ) : tone > 50 &&
                                                            tone <= 70 ? (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={OkTone3}
                                                              alt="Ok Tone 3"
                                                            />
                                                          ) : (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={GoodTone3}
                                                              alt="Good Tone 3"
                                                            />
                                                          )}
                                                        </>
                                                      )}
                                                      {word.tone ===
                                                        "tone4" && (
                                                        <>
                                                          {tone <= 50 ? (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={BadTone4}
                                                              alt="Bad Tone 4"
                                                            />
                                                          ) : tone > 50 &&
                                                            tone <= 70 ? (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={OkTone4}
                                                              alt="Ok Tone 4"
                                                            />
                                                          ) : (
                                                            <img
                                                              style={{
                                                                width: 10,
                                                              }}
                                                              src={GoodTone4}
                                                              alt="Good Tone 4"
                                                            />
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                    <span
                                                      style={{
                                                        color:
                                                          letter.pronunciation <=
                                                          50
                                                            ? "red"
                                                            : letter.pronunciation >
                                                                50 &&
                                                              letter.pronunciation <=
                                                                70
                                                            ? "#F4BC1C"
                                                            : "green",
                                                      }}
                                                    >
                                                      {character}
                                                    </span>
                                                  </div>
                                                );
                                              }
                                            });
                                        })}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {word.phonemes.map((letter) => {
                                        return (
                                          <span
                                            style={{
                                              color:
                                                letter.pronunciation <= 50
                                                  ? "red"
                                                  : letter.pronunciation > 50 &&
                                                    letter.pronunciation <= 70
                                                  ? "#F4BC1C"
                                                  : "green",
                                            }}
                                          >
                                            {letter.phoneme}{" "}
                                          </span>
                                        );
                                      })}
                                    </>
                                  )}
                                </p>
                              </div>
                            </p>
                            {/* <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            {language === "cn" && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {word.phonemes.map((letter) => {
                                    return letter.phone
                                      .split("")
                                      .map((character, index) => {
                                        if (index + 1 != letter.tone_index) {
                                          return (
                                            <div style={{ height: "50px" }}>
                                              <div
                                                style={{
                                                  height: "10px",
                                                  marginBottom: "2px",
                                                }}
                                              ></div>
                                              <span
                                                style={{
                                                  color:
                                                    letter.pronunciation <= 50
                                                      ? "red"
                                                      : letter.pronunciation >
                                                          50 &&
                                                        letter.pronunciation <=
                                                          70
                                                      ? "#F4BC1C"
                                                      : "green",
                                                }}
                                              >
                                                {character}
                                              </span>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                height: "50px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  height: "10px",
                                                  marginBottom: "2px",
                                                }}
                                              >
                                                {word.tone === "tone1" && (
                                                  <>
                                                    {tone <= 50 ? (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={BadTone1}
                                                        alt="Bad Tone 1"
                                                      />
                                                    ) : tone > 50 &&
                                                      tone <= 70 ? (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={OkTone1}
                                                        alt="Ok Tone 1"
                                                      />
                                                    ) : (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={GoodTone1}
                                                        alt="Good Tone 1"
                                                      />
                                                    )}
                                                  </>
                                                )}
                                                {word.tone === "tone2" && (
                                                  <>
                                                    {tone <= 50 ? (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={BadTone2}
                                                        alt="Bad Tone 2"
                                                      />
                                                    ) : tone > 50 &&
                                                      tone <= 70 ? (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={OkTone2}
                                                        alt="Ok Tone 2"
                                                      />
                                                    ) : (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={GoodTone2}
                                                        alt="Good Tone 2"
                                                      />
                                                    )}
                                                  </>
                                                )}
                                                {word.tone === "tone3" && (
                                                  <>
                                                    {tone <= 50 ? (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={BadTone3}
                                                        alt="Bad Tone 3"
                                                      />
                                                    ) : tone > 50 &&
                                                      tone <= 70 ? (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={OkTone3}
                                                        alt="Ok Tone 3"
                                                      />
                                                    ) : (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={GoodTone3}
                                                        alt="Good Tone 3"
                                                      />
                                                    )}
                                                  </>
                                                )}
                                                {word.tone === "tone4" && (
                                                  <>
                                                    {tone <= 50 ? (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={BadTone4}
                                                        alt="Bad Tone 4"
                                                      />
                                                    ) : tone > 50 &&
                                                      tone <= 70 ? (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={OkTone4}
                                                        alt="Ok Tone 4"
                                                      />
                                                    ) : (
                                                      <img
                                                        style={{ width: 10 }}
                                                        src={GoodTone4}
                                                        alt="Good Tone 4"
                                                      />
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                              <span
                                                style={{
                                                  color:
                                                    letter.pronunciation <= 50
                                                      ? "red"
                                                      : letter.pronunciation >
                                                          50 &&
                                                        letter.pronunciation <=
                                                          70
                                                      ? "#F4BC1C"
                                                      : "green",
                                                }}
                                              >
                                                {character}
                                              </span>
                                            </div>
                                          );
                                        }
                                      });
                                  })}
                                </div>
                              </>
                            )}
                          </div>*/}
                          </div>
                        );
                      })}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginLeft: "2%",
                        marginTop: "1%",
                      }}
                    >
                      {result_json.rear_tone === "fall" ? (
                        <img
                          src={Fall}
                          alt="Fall"
                          style={{
                            height: 40,
                            marginLeft: "6%",
                          }}
                        />
                      ) : (
                        <img
                          src={Rise}
                          alt="Rise"
                          style={{
                            height: 40,
                            marginLeft: "6%",
                          }}
                        />
                      )}
                      <img
                        onClick={() => listenAudio(result.text)}
                        src={Ear}
                        alt="ear"
                        style={{
                          height: 40,
                          marginLeft: "6%",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div style={{ display: "flex" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {result.duration}
                        {result.isPlaying ? (
                          <img
                            alt="pause"
                            className={Styles.record_animate}
                            src={Pause}
                            onClick={() => pauseAudio(index)}
                          />
                        ) : (
                          <img
                            alt="play"
                            className={Styles.record}
                            src={Play}
                            onClick={() => playAudio(index)}
                          />
                        )}
                      </div>
                      <a
                        style={{
                          marginTop: "30px",
                          marginLeft: "20px",
                        }}
                        href={result.audioLink}
                        download
                      >
                        <FontAwesomeIcon
                          style={{ color: "#1e525c", fontSize: "3em" }}
                          icon={faFileAudio}
                          className={Styles.record}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                {/* <p className={Styles.hint}>
                  <span style={{ color: "#1B535C" }}>Missed word </span>{" "}
                  Presentation
                </p> */}
                <hr />
                <Row>
                  <Col md={9} style={{ display: "flex" }}>
                    {language === "cn" && (
                      <div style={{ margin: "3%" }}>
                        <h4
                          style={{
                            color:
                              result_json.overall <= 50
                                ? "red"
                                : result_json.overall > 50 &&
                                  result_json.overall <= 70
                                ? "#F4BC1C"
                                : "green",
                            textAlign: "center",
                          }}
                        >
                          {result_json.overall}{" "}
                        </h4>
                        <FormattedMessage
                          id="overallMessage"
                          defaultMessage="Overall"
                        />
                      </div>
                    )}
                    <div style={{ margin: "3%" }}>
                      <h4
                        style={{
                          color:
                            result.fluency <= 50
                              ? "red"
                              : result.fluency > 50 && result.fluency <= 70
                              ? "#F4BC1C"
                              : "green",
                          textAlign: "center",
                        }}
                      >
                        {result.fluency}{" "}
                      </h4>
                      <FormattedMessage
                        id="fluencyMessage"
                        defaultMessage="Fluency"
                      />
                    </div>
                    <div style={{ margin: "3%" }}>
                      <h4
                        style={{
                          color:
                            result.pronunciation <= 50
                              ? "red"
                              : result.pronunciation > 50 &&
                                result.pronunciation <= 70
                              ? "#F4BC1C"
                              : "green",
                          textAlign: "center",
                        }}
                      >
                        {result.pronunciation}{" "}
                      </h4>
                      <FormattedMessage
                        id="pronunciationMessage"
                        defaultMessage="Pronunciation"
                      />
                    </div>
                    {language === "cn" && (
                      <div style={{ margin: "3%" }}>
                        <h4
                          style={{
                            color:
                              result_json.tone <= 50
                                ? "red"
                                : result_json.tone > 50 &&
                                  result_json.tone <= 70
                                ? "#F4BC1C"
                                : "green",
                            textAlign: "center",
                          }}
                        >
                          {result_json.tone}{" "}
                        </h4>
                        <FormattedMessage
                          id="toneMessage"
                          defaultMessage="Tone"
                        />
                      </div>
                    )}
                  </Col>
                  {/* <Col md={3} style={{ padding: "4%" }}>
                <span
                  style={{
                    margin: "3%",
                    textDecoration: "underline",
                    color: "#1B535C",
                  }}
                >
                  Redo this question
                </span>
              </Col> */}
                </Row>
              </Card.Body>
            </Card>
          );
        })}
        <Button
          onClick={() => generatePdf()}
          variant="primary"
          size="lg"
          className={Styles.btn}
          active
        >
          <FormattedMessage id="pdfMessage" defaultMessage="Download PDF" />
        </Button>
        <p className={Styles.footer}>
          All copyrights reserved. Voiceme powered by{" "}
          <a
            href="https://www.aispeak.ai"
            target="_blank"
            rel="noopener noreferrer"
            className={Styles.footerSpan}
          >
            aispeak
          </a>{" "}
          technology and{" "}
          <a
            href="https://languagetool.org/"
            target="_blank"
            rel="noopener noreferrer"
            className={Styles.footerSpan}
          >
            languagetool
          </a>{" "}
        </p>
      </Container>
    </div>
  );
};
