import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Routes from "./routes";
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { IntlProvider } from "react-intl";

const messagesInKorean = {
  hiiMessage: `안녕하세요!`,
  helloMessage: "여러분, 안녕하세요!",
  helloContentMessage: "함께 언어를 연습해볼까요?",
  selectSchoolMessage: "학교를 선택해주세요",
  studentLinkMessage: "학생페이지 이동",
  teacherLinkMessage: "선생님페이지 이동",
  studentMessage: "학번을 입력해주세요",
  logOutMessage: " 로그아웃",
  maleGenderMessage: "남",
  femaleGenderMessage: "여",
  microphoneMessage: "마이크 켜기",
  selectClassMessage: "강의 선택",
  selectLessonMessage: "수업 선택",
  sentenceMessage: "문장을 완성해주세요",
  retryMessage: "재시도",
  scoreButtonMessage: "회화점수 확인하기",
  loginMessage: "로그인",
  classManagementMessage: "강의 관리",
  helloTeacherMessage: "안녕하세요, 선생님!",
  createClassMessage: "강의 만들기",
  uploadStudentMessage: "학생 정보 올리기",
  changeClassMessage: "강의 제목을 적어주세요! (예: 초보반, 비즈니스 영어)",
  changeLessonsMessage: "수업 제목을 만들어주세요! (예: 레스토랑에서 회화하기)",
  uploadwordFileMessage: "단어 파일 추가",
  uploadStudentFileMessage: "수강생 파일 추가",
  classesMessage: "강의",
  lessonsMessage: "수업",
  deleteMessage: "삭제",
  removeMessage: "제거",
  saveChangesMessage: "저장",
  selectLanguageMessage: "언어를 선택해주세요",
  classMessage: "수업",
  completenessMessage: "완성도",
  pronunciationMessage: "발음",
  fluencyMessage: "능숙도",
  overallMessage: "종합",
  toneMessage: "성조",
  scoreMessage: "점수",
  stressMessage: "강조",
  pdfMessage: "PDF 다운받기",
  singleWordMessage: "단어추가",
  singleStudentMessage: "수강생 파일 추가",
  forAdministratorsMessage: "선생님 로그인",
  userNameMessage: "사용자 이름",
  passwordMessage: "비밀번호",
  createClassLanguageMessage: "강의 만들기",
  toggleMessage: "성별을 선택해주시고 마이크를 켜주세요 ",
  completeSentenceMessage: "모든 문장을 완성해주세요~",
  genderMessage: "성별을 선택해주세요",
};

const messagesInEnglish = {
  hiiMessage: `Hi`,
  helloMessage: "Hello",
  helloContentMessage: "Let's improve our language speaking skills!",
  selectSchoolMessage: "Select your school",
  studentLinkMessage: "Student Click here",
  teacherLinkMessage: "Teacher Click here",
  studentMessage: "Enter your student number",
  logOutMessage: " Log out",
  maleGenderMessage: "Male",
  femaleGenderMessage: "Female",
  microphoneMessage: "Enable microphone",
  selectClassMessage: "Select your class",
  selectLessonMessage: "Select your lesson",
  sentenceMessage: "Write your sentence here",
  retryMessage: "Re-try",
  scoreButtonMessage: "Check my speaking scores",
  loginMessage: "Log in",
  classManagementMessage: "Class Management",
  helloTeacherMessage: "Hello Teacher!",
  createClassMessage: "Create your class",
  uploadStudentMessage: "Upload Student Information",
  changeClassMessage:
    "Write your class name here e.g. beginner, business english, etc",
  changeLessonsMessage:
    "Write your lesson name here e.g. chapter 1, conversation, restaurant, etc",
  uploadwordFileMessage: "Upload word list from file...",
  uploadStudentFileMessage: "Upload student list from file...",
  classesMessage: "Classes",
  lessonsMessage: "Lessons",
  deleteMessage: "DELETE",
  removeMessage: "Remove",
  saveChangesMessage: "Save changes",
  selectLanguageMessage: "Please select the language for",
  classMessage: "Class",
  completenessMessage: "Completeness",
  pronunciationMessage: "Pronunciation",
  fluencyMessage: "Fluency",
  overallMessage: "Overall",
  toneMessage: "Tone",
  scoreMessage: "Score",
  stressMessage: "Stress",
  pdfMessage: "Download PDF",
  singleWordMessage: "Add a single word",
  singleStudentMessage: "Add a single student",
  forAdministratorsMessage: "Teachers Log-in",
  userNameMessage: "Username",
  passwordMessage: "Password",
  createClassLanguageMessage: "Create Class",
  toggleMessage:
    "Remember to toggle your gender and check microphone permission",
  completeSentenceMessage: "Oops! Please remember to complete all sentences",
  genderMessage: "Select gender",
};

const messagesInChinese = {
  hiiMessage: `嗨！`,
  helloMessage: "嗨 你好！",
  helloContentMessage: "一起来增强我们的口语能力吧！",
  selectSchoolMessage: "选择学校",
  studentLinkMessage: "学生请按这",
  teacherLinkMessage: "老师请按这",
  studentMessage: "填入您的学生号码",
  logOutMessage: " 登出",
  maleGenderMessage: "男",
  femaleGenderMessage: "女",
  microphoneMessage: "激活麦克风",
  selectClassMessage: "选择课堂",
  selectLessonMessage: "选课",
  retryMessage: "再试一次",
  scoreButtonMessage: "检视分数",
  loginMessage: "登入",
  classManagementMessage: "课堂管理",
  helloTeacherMessage: "老师好",
  createClassMessage: "创建课堂",
  uploadStudentMessage: "上传课堂资讯",
  changeClassMessage: "填入课堂名称 如：基础英文, 一般会话， 等",
  changeLessonsMessage: "填入课程名称 如： 第一课, 会话, 餐厅， 等",
  uploadwordFileMessage: "上传单词表文件",
  uploadStudentFileMessage: "上传学生名单文件",
  classesMessage: "课堂",
  lessonsMessage: "课程",
  deleteMessage: "删除",
  removeMessage: "移除",
  saveChangesMessage: "储存",
  selectLanguageMessage: "请选择课程语言",
  classMessage: "课",
  completenessMessage: "完整度",
  pronunciationMessage: "发音",
  fluencyMessage: "流利度",
  overallMessage: "总分",
  toneMessage: "声调",
  scoreMessage: "分数",
  stressMessage: "重音",
  pdfMessage: "下载 PDF",
  sentenceMessage: "在此栏造句",
  singleWordMessage: "新增单词",
  singleStudentMessage: "上传学生名单文件",
  forAdministratorsMessage: "老师登入",
  userNameMessage: "用户名",
  passwordMessage: "密码",
  createClassLanguageMessage: "创建课堂",
  toggleMessage: "请记得勾选您的性别和激活麦克风",
  completeSentenceMessage: "请记得填写所有句子",
  genderMessage: "请选择性别",
};

const refreshAuthLogic = (failedRequest) =>
  axios
    .post("/api/v1/refresh-token", {
      user_id: localStorage.getItem("userID"),
      refresh_token: localStorage.getItem("refreshToken"),
    })
    .then((response) => {
      localStorage.setItem("accessToken", response.data.data.accessToken);
      localStorage.setItem("refreshToken", response.data.data.refreshToken);
      failedRequest.response.config.headers["authorization"] =
        "bearer " + response.data.data.accessToken;
      return Promise.resolve();
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic);

const PrivateRoute = ({ component: Component, ...rest }) => {
  let acessToken = localStorage.getItem("accessToken");
  let refreshToken = localStorage.getItem("refreshToken");
  return (
    <Route
      {...rest}
      render={(props) =>
        acessToken && refreshToken ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

function withProps(Component, props) {
  return function (matchProps) {
    return <Component {...props} {...matchProps} />;
  };
}

function App() {
  const [textLanguage, setTextLanguage] = useState("en");

  return (
    <IntlProvider
      messages={
        textLanguage === "en"
          ? messagesInEnglish
          : textLanguage === "kr"
          ? messagesInKorean
          : messagesInChinese
      }
      locale={textLanguage}
      defaultLocale="en"
    >
      <Router>
        <Switch>
          <PrivateRoute
            exact
            path="/admin-dashboard"
            component={withProps(Routes.AdminDashboard, { setTextLanguage })}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/lesson-page"
            component={withProps(Routes.LessonPage, { setTextLanguage })}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/result/:id"
            component={withProps(Routes.Result, { setTextLanguage })}
          ></PrivateRoute>
          <Route
            exact
            path="/:schoolSlug"
            component={withProps(Routes.SchoolHome, { setTextLanguage })}
          ></Route>
          <Route
            exact
            path="/"
            component={withProps(Routes.Home, { setTextLanguage })}
          ></Route>
        </Switch>
      </Router>
    </IntlProvider>
  );
}

export default App;
